export default [
    {
        text: "Aa en Hunze",
        value: "Aa en Hunze",
    },
    {
        text: "Aalsmeer",
        value: "Aalsmeer",
    },
    {
        text: "Aalten",
        value: "Aalten",
    },
    {
        text: "Achtkarspelen",
        value: "Achtkarspelen",
    },
    {
        text: "Alblasserdam",
        value: "Alblasserdam",
    },
    {
        text: "Albrandswaard",
        value: "Albrandswaard",
    },
    {
        text: "Alkmaar",
        value: "Alkmaar",
    },
    {
        text: "Almelo",
        value: "Almelo",
    },
    {
        text: "Almere",
        value: "Almere",
    },
    {
        text: "Alphen aan den Rijn",
        value: "Alphen aan den Rijn",
    },
    {
        text: "Alphen-Chaam",
        value: "Alphen-Chaam",
    },
    {
        text: "Altena",
        value: "Altena",
    },
    {
        text: "Ameland",
        value: "Ameland",
    },
    {
        text: "Amersfoort",
        value: "Amersfoort",
    },
    {
        text: "Amstelveen",
        value: "Amstelveen",
    },
    {
        text: "Amsterdam",
        value: "Amsterdam",
    },
    {
        text: "Apeldoorn",
        value: "Apeldoorn",
    },
    {
        text: "Appingedam",
        value: "Appingedam",
    },
    {
        text: "Arnhem",
        value: "Arnhem",
    },
    {
        text: "Assen",
        value: "Assen",
    },
    {
        text: "Asten",
        value: "Asten",
    },
    {
        text: "Baarle-Nassau",
        value: "Baarle-Nassau",
    },
    {
        text: "Baarn",
        value: "Baarn",
    },
    {
        text: "Barendrecht",
        value: "Barendrecht",
    },
    {
        text: "Barneveld",
        value: "Barneveld",
    },
    {
        text: "Beek",
        value: "Beek",
    },
    {
        text: "Beekdaelen",
        value: "Beekdaelen",
    },
    {
        text: "Beesel",
        value: "Beesel",
    },
    {
        text: "Berg en Dal",
        value: "Berg en Dal",
    },
    {
        text: "Bergeijk",
        value: "Bergeijk",
    },
    {
        text: "Bergen (L.)",
        value: "Bergen (L.)",
    },
    {
        text: "Bergen (NH.)",
        value: "Bergen (NH.)",
    },
    {
        text: "Bergen op Zoom",
        value: "Bergen op Zoom",
    },
    {
        text: "Berkelland",
        value: "Berkelland",
    },
    {
        text: "Bernheze",
        value: "Bernheze",
    },
    {
        text: "Best",
        value: "Best",
    },
    {
        text: "Beuningen",
        value: "Beuningen",
    },
    {
        text: "Beverwijk",
        value: "Beverwijk",
    },
    {
        text: "De Bilt",
        value: "De Bilt",
    },
    {
        text: "Bladel",
        value: "Bladel",
    },
    {
        text: "Blaricum",
        value: "Blaricum",
    },
    {
        text: "Bloemendaal",
        value: "Bloemendaal",
    },
    {
        text: "Bodegraven-Reeuwijk",
        value: "Bodegraven-Reeuwijk",
    },
    {
        text: "Boekel",
        value: "Boekel",
    },
    {
        text: "Borger-Odoorn",
        value: "Borger-Odoorn",
    },
    {
        text: "Borne",
        value: "Borne",
    },
    {
        text: "Borsele",
        value: "Borsele",
    },
    // {
    //   text: "Boxmeer",
    //   value: "Boxmeer",
    // },
    {
        text: "Boxtel",
        value: "Boxtel",
    },
    {
        text: "Breda",
        value: "Breda",
    },
    {
        text: "Brielle",
        value: "Brielle",
    },
    {
        text: "Bronckhorst",
        value: "Bronckhorst",
    },
    {
        text: "Brummen",
        value: "Brummen",
    },
    {
        text: "Brunssum",
        value: "Brunssum",
    },
    {
        text: "Bunnik",
        value: "Bunnik",
    },
    {
        text: "Bunschoten",
        value: "Bunschoten",
    },
    {
        text: "Buren",
        value: "Buren",
    },
    {
        text: "Capelle aan den IJssel",
        value: "Capelle aan den IJssel",
    },
    {
        text: "Castricum",
        value: "Castricum",
    },
    {
        text: "Coevorden",
        value: "Coevorden",
    },
    {
        text: "Cranendonck",
        value: "Cranendonck",
    },
    {
        text: "Cuijk",
        value: "Cuijk",
    },
    // {
    //   text: "Culemborg",
    //   value: "Culemborg",
    // },
    {
        text: "Dalfsen",
        value: "Dalfsen",
    },
    {
        text: "Dantumadiel",
        value: "Dantumadiel",
    },
    {
        text: "Delft",
        value: "Delft",
    },
    {
        text: "Delfzijl",
        value: "Delfzijl",
    },
    {
        text: "Deurne",
        value: "Deurne",
    },
    {
        text: "Deventer",
        value: "Deventer",
    },
    {
        text: "Diemen",
        value: "Diemen",
    },
    {
        text: "Dijk en Waard",
        value: "Dijk en Waard",
    },
    {
        text: "Dinkelland",
        value: "Dinkelland",
    },
    {
        text: "Doesburg",
        value: "Doesburg",
    },
    {
        text: "Doetinchem",
        value: "Doetinchem",
    },
    {
        text: "Dongen",
        value: "Dongen",
    },
    {
        text: "Dordrecht",
        value: "Dordrecht",
    },
    {
        text: "Drechterland",
        value: "Drechterland",
    },
    {
        text: "Drimmelen",
        value: "Drimmelen",
    },
    {
        text: "Dronten",
        value: "Dronten",
    },
    {
        text: "Druten",
        value: "Druten",
    },
    {
        text: "Duiven",
        value: "Duiven",
    },
    {
        text: "Echt-Susteren",
        value: "Echt-Susteren",
    },
    {
        text: "Edam-Volendam",
        value: "Edam-Volendam",
    },
    {
        text: "Ede",
        value: "Ede",
    },
    {
        text: "Eemnes",
        value: "Eemnes",
    },
    {
        text: "Eemsdelta",
        value: "Eemsdelta",
    },
    {
        text: "Eersel",
        value: "Eersel",
    },
    {
        text: "Eijsden-Margraten",
        value: "Eijsden-Margraten",
    },
    {
        text: "Eindhoven",
        value: "Eindhoven",
    },
    {
        text: "Elburg",
        value: "Elburg",
    },
    {
        text: "Emmen",
        value: "Emmen",
    },
    {
        text: "Enkhuizen",
        value: "Enkhuizen",
    },
    {
        text: "Enschede",
        value: "Enschede",
    },
    {
        text: "Epe",
        value: "Epe",
    },
    {
        text: "Ermelo",
        value: "Ermelo",
    },
    {
        text: "Etten-Leur",
        value: "Etten-Leur",
    },
    {
        text: "De Fryske Marren",
        value: "De Fryske Marren",
    },
    {
        text: "Geertruidenberg",
        value: "Geertruidenberg",
    },
    {
        text: "Geldrop-Mierlo",
        value: "Geldrop-Mierlo",
    },
    {
        text: "Gemert-Bakel",
        value: "Gemert-Bakel",
    },
    {
        text: "Gennep",
        value: "Gennep",
    },
    {
        text: "Gilze en Rijen",
        value: "Gilze en Rijen",
    },
    {
        text: "Goeree-Overflakkee",
        value: "Goeree-Overflakkee",
    },
    {
        text: "Goes",
        value: "Goes",
    },
    {
        text: "Goirle",
        value: "Goirle",
    },
    {
        text: "Gooise Meren",
        value: "Gooise Meren",
    },
    {
        text: "Gorinchem",
        value: "Gorinchem",
    },
    {
        text: "Gouda",
        value: "Gouda",
    },
    // {
    //   text: "Grave",
    //   value: "Grave",
    // },
    {
        text: "s-Gravenhage",
        value: "s-Gravenhage",
    },
    {
        text: "Groningen",
        value: "Groningen",
    },
    {
        text: "Gulpen-Wittem",
        value: "Gulpen-Wittem",
    },
    {
        text: "Haaksbergen",
        value: "Haaksbergen",
    },
    // {
    //   text: "Haaren",
    //   value: "Haaren",
    // },
    {
        text: "Haarlem",
        value: "Haarlem",
    },
    {
        text: "Haarlemmermeer",
        value: "Haarlemmermeer",
    },
    {
        text: "Halderberge",
        value: "Halderberge",
    },
    {
        text: "Hardenberg",
        value: "Hardenberg",
    },
    {
        text: "Harderwijk",
        value: "Harderwijk",
    },
    {
        text: "Hardinxveld-Giessendam",
        value: "Hardinxveld-Giessendam",
    },
    {
        text: "Harlingen",
        value: "Harlingen",
    },
    {
        text: "Hattem",
        value: "Hattem",
    },
    {
        text: "Heemskerk",
        value: "Heemskerk",
    },
    {
        text: "Heemstede",
        value: "Heemstede",
    },
    {
        text: "Heerde",
        value: "Heerde",
    },
    {
        text: "Heerenveen",
        value: "Heerenveen",
    },
    // {
    //   text: "Heerhugowaard",
    //   value: "Heerhugowaard",
    // },
    {
        text: "Heerlen",
        value: "Heerlen",
    },
    {
        text: "Heeze-Leende",
        value: "Heeze-Leende",
    },
    {
        text: "Heiloo",
        value: "Heiloo",
    },
    {
        text: "Den Helder",
        value: "Den Helder",
    },
    {
        text: "Hellendoorn",
        value: "Hellendoorn",
    },
    {
        text: "Hellevoetsluis",
        value: "Hellevoetsluis",
    },
    {
        text: "Helmond",
        value: "Helmond",
    },
    {
        text: "Hendrik-Ido-Ambacht",
        value: "Hendrik-Ido-Ambacht",
    },
    {
        text: "Hengelo",
        value: "Hengelo",
    },
    {
        text: "'s-Hertogenbosch",
        value: "'s-Hertogenbosch",
    },
    {
        text: "Heumen",
        value: "Heumen",
    },
    {
        text: "Heusden",
        value: "Heusden",
    },
    {
        text: "Hillegom",
        value: "Hillegom",
    },
    {
        text: "Hilvarenbeek",
        value: "Hilvarenbeek",
    },
    {
        text: "Hilversum",
        value: "Hilversum",
    },
    {
        text: "Hoeksche Waard",
        value: "Hoeksche Waard",
    },
    {
        text: "Hof van Twente",
        value: "Hof van Twente",
    },
    {
        text: "Het Hogeland",
        value: "Het Hogeland",
    },
    {
        text: "Hollands Kroon",
        value: "Hollands Kroon",
    },
    {
        text: "Hoogeveen",
        value: "Hoogeveen",
    },
    {
        text: "Hoorn",
        value: "Hoorn",
    },
    {
        text: "Horst aan de Maas",
        value: "Horst aan de Maas",
    },
    {
        text: "Houten",
        value: "Houten",
    },
    {
        text: "Huizen",
        value: "Huizen",
    },
    {
        text: "Hulst",
        value: "Hulst",
    },
    {
        text: "IJsselstein",
        value: "IJsselstein",
    },
    {
        text: "Kaag en Braassem",
        value: "Kaag en Braassem",
    },
    {
        text: "Kampen",
        value: "Kampen",
    },
    {
        text: "Kapelle",
        value: "Kapelle",
    },
    {
        text: "Katwijk",
        value: "Katwijk",
    },
    {
        text: "Kerkrade",
        value: "Kerkrade",
    },
    {
        text: "Koggenland",
        value: "Koggenland",
    },
    {
        text: "Krimpen aan den IJssel",
        value: "Krimpen aan den IJssel",
    },
    {
        text: "Krimpenerwaard",
        value: "Krimpenerwaard",
    },
    {
        text: "Laarbeek",
        value: "Laarbeek",
    },
    {
        text: "Land van Cuijk",
        value: "Land van Cuijk",
    },
    // {
    //   text: "Landerd",
    //   value: "Landerd",
    // },
    {
        text: "Landgraaf",
        value: "Landgraaf",
    },
    {
        text: "Landsmeer",
        value: "Landsmeer",
    },
    // {
    //   text: "Langedijk",
    //   value: "Langedijk",
    // },
    {
        text: "Lansingerland",
        value: "Lansingerland",
    },
    {
        text: "Laren",
        value: "Laren",
    },
    {
        text: "Leeuwarden",
        value: "Leeuwarden",
    },
    {
        text: "Leiden",
        value: "Leiden",
    },
    {
        text: "Leiderdorp",
        value: "Leiderdorp",
    },
    {
        text: "Leidschendam-Voorburg",
        value: "Leidschendam-Voorburg",
    },
    {
        text: "Lelystad",
        value: "Lelystad",
    },
    {
        text: "Leudal",
        value: "Leudal",
    },
    {
        text: "Leusden",
        value: "Leusden",
    },
    {
        text: "Lingewaard",
        value: "Lingewaard",
    },
    {
        text: "Lisse",
        value: "Lisse",
    },
    {
        text: "Lochem",
        value: "Lochem",
    },
    {
        text: "Loon op Zand",
        value: "Loon op Zand",
    },
    {
        text: "Lopik",
        value: "Lopik",
    },
    // {
    //   text: "Loppersum",
    //   value: "Loppersum",
    // },
    {
        text: "Losser",
        value: "Losser",
    },
    {
        text: "Maasdriel",
        value: "Maasdriel",
    },
    {
        text: "Maasgouw",
        value: "Maasgouw",
    },
    {
        text: "Maashorst",
        value: "Maashorst",
    },
    {
        text: "Maassluis",
        value: "Maassluis",
    },
    {
        text: "Maastricht",
        value: "Maastricht",
    },
    {
        text: "Medemblik",
        value: "Medemblik",
    },
    {
        text: "Meerssen",
        value: "Meerssen",
    },
    {
        text: "Meierijstad",
        value: "Meierijstad",
    },
    {
        text: "Meppel",
        value: "Meppel",
    },
    {
        text: "Middelburg",
        value: "Middelburg",
    },
    {
        text: "Midden-Delfland",
        value: "Midden-Delfland",
    },
    {
        text: "Midden-Drenthe",
        value: "Midden-Drenthe",
    },
    {
        text: "Midden-Groningen",
        value: "Midden-Groningen",
    },
    // {
    //   text: "Mill en Sint Hubert",
    //   value: "Mill en Sint Hubert",
    // },
    {
        text: "Moerdijk",
        value: "Moerdijk",
    },
    {
        text: "Molenlanden",
        value: "Molenlanden",
    },
    {
        text: "Montferland",
        value: "Montferland",
    },
    {
        text: "Montfoort",
        value: "Montfoort",
    },
    {
        text: "Mook en Middelaar",
        value: "Mook en Middelaar",
    },
    {
        text: "Neder-Betuwe",
        value: "Neder-Betuwe",
    },
    {
        text: "Nederweert",
        value: "Nederweert",
    },
    {
        text: "Nieuwegein",
        value: "Nieuwegein",
    },
    {
        text: "Nieuwkoop",
        value: "Nieuwkoop",
    },
    {
        text: "Nijkerk",
        value: "Nijkerk",
    },
    {
        text: "Nijmegen",
        value: "Nijmegen",
    },
    {
        text: "Nissewaard",
        value: "Nissewaard",
    },
    {
        text: "Noardeast-Fryslân",
        value: "Noardeast-Fryslân",
    },
    {
        text: "Noord-Beveland",
        value: "Noord-Beveland",
    },
    {
        text: "Noordenveld",
        value: "Noordenveld",
    },
    {
        text: "Noordoostpolder",
        value: "Noordoostpolder",
    },
    {
        text: "Noordwijk",
        value: "Noordwijk",
    },
    {
        text: "Nuenen, Gerwen en Nederwetten",
        value: "Nuenen, Gerwen en Nederwetten",
    },
    {
        text: "Nunspeet",
        value: "Nunspeet",
    },
    {
        text: "Oegstgeest",
        value: "Oegstgeest",
    },
    {
        text: "Oirschot",
        value: "Oirschot",
    },
    {
        text: "Oisterwijk",
        value: "Oisterwijk",
    },
    {
        text: "Oldambt",
        value: "Oldambt",
    },
    {
        text: "Oldebroek",
        value: "Oldebroek",
    },
    {
        text: "Oldenzaal",
        value: "Oldenzaal",
    },
    {
        text: "Olst-Wijhe",
        value: "Olst-Wijhe",
    },
    {
        text: "Ommen",
        value: "Ommen",
    },
    {
        text: "Oost Gelre",
        value: "Oost Gelre",
    },
    {
        text: "Oosterhout",
        value: "Oosterhout",
    },
    {
        text: "Ooststellingwerf",
        value: "Ooststellingwerf",
    },
    {
        text: "Oostzaan",
        value: "Oostzaan",
    },
    {
        text: "Opmeer",
        value: "Opmeer",
    },
    {
        text: "Opsterland",
        value: "Opsterland",
    },
    {
        text: "Oss",
        value: "Oss",
    },
    {
        text: "Oude IJsselstreek",
        value: "Oude IJsselstreek",
    },
    {
        text: "Ouder-Amstel",
        value: "Ouder-Amstel",
    },
    {
        text: "Oudewater",
        value: "Oudewater",
    },
    {
        text: "Overbetuwe",
        value: "Overbetuwe",
    },
    {
        text: "Papendrecht",
        value: "Papendrecht",
    },
    {
        text: "Peel en Maas",
        value: "Peel en Maas",
    },
    {
        text: "Pekela",
        value: "Pekela",
    },
    {
        text: "Pijnacker-Nootdorp",
        value: "Pijnacker-Nootdorp",
    },
    {
        text: "Purmerend",
        value: "Purmerend",
    },
    {
        text: "Putten",
        value: "Putten",
    },
    {
        text: "Raalte",
        value: "Raalte",
    },
    {
        text: "Reimerswaal",
        value: "Reimerswaal",
    },
    {
        text: "Renkum",
        value: "Renkum",
    },
    {
        text: "Renswoude",
        value: "Renswoude",
    },
    {
        text: "Reusel-De Mierden",
        value: "Reusel-De Mierden",
    },
    {
        text: "Rheden",
        value: "Rheden",
    },
    {
        text: "Rhenen",
        value: "Rhenen",
    },
    {
        text: "Ridderkerk",
        value: "Ridderkerk",
    },
    {
        text: "Rijssen-Holten",
        value: "Rijssen-Holten",
    },
    {
        text: "Rijswijk",
        value: "Rijswijk",
    },
    {
        text: "Roerdalen",
        value: "Roerdalen",
    },
    {
        text: "Roermond",
        value: "Roermond",
    },
    {
        text: "De Ronde Venen",
        value: "De Ronde Venen",
    },
    {
        text: "Roosendaal",
        value: "Roosendaal",
    },
    {
        text: "Rotterdam",
        value: "Rotterdam",
    },
    {
        text: "Rozendaal",
        value: "Rozendaal",
    },
    {
        text: "Rucphen",
        value: "Rucphen",
    },
    {
        text: "Schagen",
        value: "Schagen",
    },
    {
        text: "Scherpenzeel",
        value: "Scherpenzeel",
    },
    {
        text: "Schiedam",
        value: "Schiedam",
    },
    {
        text: "Schiermonnikoog",
        value: "Schiermonnikoog",
    },
    {
        text: "Schouwen-Duiveland",
        value: "Schouwen-Duiveland",
    },
    {
        text: "Simpelveld",
        value: "Simpelveld",
    },
    // {
    //   text: "Sint Anthonis",
    //   value: "Sint Anthonis",
    // },
    {
        text: "Sint-Michielsgestel",
        value: "Sint-Michielsgestel",
    },
    {
        text: "Sittard-Geleen",
        value: "Sittard-Geleen",
    },
    {
        text: "Sliedrecht",
        value: "Sliedrecht",
    },
    {
        text: "Sluis",
        value: "Sluis",
    },
    {
        text: "Smallingerland",
        value: "Smallingerland",
    },
    {
        text: "Soest",
        value: "Soest",
    },
    {
        text: "Someren",
        value: "Someren",
    },
    {
        text: "Son en Breugel",
        value: "Son en Breugel",
    },
    {
        text: "Stadskanaal",
        value: "Stadskanaal",
    },
    {
        text: "Staphorst",
        value: "Staphorst",
    },
    {
        text: "Stede Broec",
        value: "Stede Broec",
    },
    {
        text: "Steenbergen",
        value: "Steenbergen",
    },
    {
        text: "Steenwijkerland",
        value: "Steenwijkerland",
    },
    {
        text: "Stein",
        value: "Stein",
    },
    {
        text: "Stichtse Vecht",
        value: "Stichtse Vecht",
    },
    {
        text: "Súdwest-Fryslân",
        value: "Súdwest-Fryslân",
    },
    {
        text: "Terneuzen",
        value: "Terneuzen",
    },
    {
        text: "Terschelling",
        value: "Terschelling",
    },
    {
        text: "Texel",
        value: "Texel",
    },
    {
        text: "Teylingen",
        value: "Teylingen",
    },
    {
        text: "Tholen",
        value: "Tholen",
    },
    {
        text: "Tiel",
        value: "Tiel",
    },
    {
        text: "Tilburg",
        value: "Tilburg",
    },
    {
        text: "Tubbergen",
        value: "Tubbergen",
    },
    {
        text: "Twenterand",
        value: "Twenterand",
    },
    {
        text: "Tynaarlo",
        value: "Tynaarlo",
    },
    {
        text: "Tytsjerksteradiel",
        value: "Tytsjerksteradiel",
    },
    // {
    //   text: "Uden",
    //   value: "Uden",
    // },
    {
        text: "Uitgeest",
        value: "Uitgeest",
    },
    {
        text: "Uithoorn",
        value: "Uithoorn",
    },
    {
        text: "Urk",
        value: "Urk",
    },
    {
        text: "Utrecht",
        value: "Utrecht",
    },
    {
        text: "Utrechtse Heuvelrug",
        value: "Utrechtse Heuvelrug",
    },
    {
        text: "Vaals",
        value: "Vaals",
    },
    {
        text: "Valkenburg aan de Geul",
        value: "Valkenburg aan de Geul",
    },
    {
        text: "Valkenswaard",
        value: "Valkenswaard",
    },
    {
        text: "Veendam",
        value: "Veendam",
    },
    {
        text: "Veenendaal",
        value: "Veenendaal",
    },
    {
        text: "Veere",
        value: "Veere",
    },
    {
        text: "Veldhoven",
        value: "Veldhoven",
    },
    {
        text: "Velsen",
        value: "Velsen",
    },
    {
        text: "Venlo",
        value: "Venlo",
    },
    {
        text: "Venray",
        value: "Venray",
    },
    {
        text: "Vijfheerenlanden",
        value: "Vijfheerenlanden",
    },
    {
        text: "Vlaardingen",
        value: "Vlaardingen",
    },
    {
        text: "Vlieland",
        value: "Vlieland",
    },
    {
        text: "Vlissingen",
        value: "Vlissingen",
    },
    {
        text: "Voerendaal",
        value: "Voerendaal",
    },
    {
        text: "Voorschoten",
        value: "Voorschoten",
    },
    {
        text: "Voorst",
        value: "Voorst",
    },
    {
        text: "Vught",
        value: "Vught",
    },
    {
        text: "Waadhoeke",
        value: "Waadhoeke",
    },
    {
        text: "Waalre",
        value: "Waalre",
    },
    {
        text: "Waalwijk",
        value: "Waalwijk",
    },
    {
        text: "Waddinxveen",
        value: "Waddinxveen",
    },
    {
        text: "Wageningen",
        value: "Wageningen",
    },
    {
        text: "Wassenaar",
        value: "Wassenaar",
    },
    {
        text: "Waterland",
        value: "Waterland",
    },
    {
        text: "Weert",
        value: "Weert",
    },
    {
        text: "Weesp",
        value: "Weesp",
    },
    {
        text: "West Betuwe",
        value: "West Betuwe",
    },
    {
        text: "West Maas en Waal",
        value: "West Maas en Waal",
    },
    {
        text: "Westerkwartier",
        value: "Westerkwartier",
    },
    {
        text: "Westerveld",
        value: "Westerveld",
    },
    {
        text: "Westervoort",
        value: "Westervoort",
    },
    {
        text: "Westerwolde",
        value: "Westerwolde",
    },
    {
        text: "Westland",
        value: "Westland",
    },
    {
        text: "Weststellingwerf",
        value: "Weststellingwerf",
    },
    {
        text: "Westvoorne",
        value: "Westvoorne",
    },
    {
        text: "Wierden",
        value: "Wierden",
    },
    {
        text: "Wijchen",
        value: "Wijchen",
    },
    {
        text: "Wijdemeren",
        value: "Wijdemeren",
    },
    {
        text: "Wijk bij Duurstede",
        value: "Wijk bij Duurstede",
    },
    {
        text: "Winterswijk",
        value: "Winterswijk",
    },
    {
        text: "Woensdrecht",
        value: "Woensdrecht",
    },
    {
        text: "Woerden",
        value: "Woerden",
    },
    {
        text: "De Wolden",
        value: "De Wolden",
    },
    {
        text: "Wormerland",
        value: "Wormerland",
    },
    {
        text: "Woudenberg",
        value: "Woudenberg",
    },
    {
        text: "Zaanstad",
        value: "Zaanstad",
    },
    {
        text: "Zaltbommel",
        value: "Zaltbommel",
    },
    {
        text: "Zandvoort",
        value: "Zandvoort",
    },
    {
        text: "Zeewolde",
        value: "Zeewolde",
    },
    {
        text: "Zeist",
        value: "Zeist",
    },
    {
        text: "Zevenaar",
        value: "Zevenaar",
    },
    {
        text: "Zoetermeer",
        value: "Zoetermeer",
    },
    {
        text: "Zoeterwoude",
        value: "Zoeterwoude",
    },
    {
        text: "Zuidplas",
        value: "Zuidplas",
    },
    {
        text: "Zundert",
        value: "Zundert",
    },
    {
        text: "Zutphen",
        value: "Zutphen",
    },
    {
        text: "Zwartewaterland",
        value: "Zwartewaterland",
    },
    {
        text: "Zwijndrecht",
        value: "Zwijndrecht",
    },
    {
        text: "Zwolle",
        value: "Zwolle",
    },
];
