<template>
  <ValidationProvider
    ref="validator"
    :vid="name"
    :name="name"
    :rules="rules"
    v-slot="{ valid, errors, validate }"
  >
    <template v-if="label">
      <slot name="label">
        <label class="dual-label" v-if="typeof label === 'string'" :for="name">{{
          label
        }}</label>
        <label class="dual-label" v-else :for="name">
          <span>
            {{ label.main }}
          </span>
          <span>
            {{ label.secondary }}
          </span>
        </label>
      </slot>
    </template>
    <Multiselect
      v-if="options"
      :showLabels="false"
      :selectLabel="$t('formControls.multiselect.select')"
      :selectedLabel="$t('formControls.multiselect.selected')"
      :deselectLabel="$t('formControls.multiselect.deselect')"
      :placeholder="placeholder"
      class="mb-2"
      :class="{ invalid: !valid }"
      v-bind="$attrs"
      :value="value"
      :options="options"
      @input="onInput"
      :multiple="multiple"
      :close-on-select="!multiple"
      :clear-on-select="true"
      :preserve-search="true"
      label="text"
      track-by="value"
      @close="validate(name)"
    >
      <!-- <template slot="caret">
        <img
          class="multiselect__custom-caret"
          src="@/assets/chevron-down.png"
        />
      </template> -->

      <template v-if="multiple" slot="tag" slot-scope="{ option }">
        <b-badge
          pill
          variant="primary"
          class="cursor-pointer px-x py-1 d-inline-flex align-items-center ml-1"
          >{{ option.text }}
          <span class="multiselect__custom-remove ml-1" @click="remove(option)"
            >&times;</span
          >
        </b-badge>
      </template>
      <template v-else slot="singleLabel" slot-scope="{ option }">
        <b-badge
          pill
          variant="primary"
          class="cursor-pointer px-x py-1 d-inline-flex align-items-center ml-1"
          >{{ option.text }}
        </b-badge>
      </template>
    </Multiselect>
    <small class="text-danger" v-if="errors.length">{{ errors[0] }}</small>
  </ValidationProvider>
</template>

<script>
import Multiselect from "vue-multiselect";
import inputMixin from "@/mixins/inputMixin";
export default {
  mixins: [inputMixin],
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    label: {
      type: [String, Object],
    },
  },
  components: {
    Multiselect,
  },
  methods: {
    remove(option) {
      const removedOptionIdx = this.value.findIndex((op) => op.value === option.value);
      if (removedOptionIdx > -1) {
        const newVal = this.value.filter((_, i) => i !== removedOptionIdx);
        this.$emit("input", newVal);
      }
    },
  },
};
</script>

<style lang="scss">
.multiselect {
  color: $gray4;
  line-height: 1;

  &__tags {
    border-color: $border-color;
  }

  &__custom-caret {
    position: absolute;
    right: 12px;
    top: 13px;
    z-index: 1111;
  }

  &__custom-remove {
    font-size: 18px;
  }
}

.multiselect__placeholder {
  margin-bottom: 0;
}

.multiselect,
.multiselect__input,
.multiselect__single,
.multiselect__placeholder {
  color: $gray4;
  font-size: 1.125rem;

  @include md {
    font-size: 1rem;
  }
}

.multiselect__tags {
  padding: 0.63rem 0.8125rem;
}

.multiselect__single {
  margin-bottom: 4px;
}

.multiselect__select:before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: "";
  display: inline-block;
  height: 13px;

  position: relative;
  vertical-align: top;
  width: 13px;
  border-color: #2d2d2d !important;
  transform: rotate(135deg);
  top: 8%;
}

.multiselect--active .multiselect__select {
  transform: none;
}

.multiselect__select:before {
  z-index: 1111;
}

.multiselect--active {
  z-index: 1111;
}

.dual-label,
label {
  font-weight: 500;
  font-size: 0.875rem;

  span:nth-of-type(2) {
    color: #9ea3ae;
  }
}
</style>
