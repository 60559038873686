<template>
  <Card>
    <div class="formContainer listingFilter">
      <b-form
        @submit.prevent="onSubmit"
        class="d-flex align-items-center w-100 formContainer__inputsContainer px-4 pt-2 justify-content-between"
      >
        <div
          class="d-flex flex-lg-row flex-column align-items-center justify-content-lg-between justify-content-lg-start justify-content-center formContainer__inputsContainer__inputs"
        >
          <div
            v-for="{
              name,
              label,
              externalLabel = false,
              component,
              options = [],
              multiple = true,
              colClass,
              ...attrs
            } in formFields"
            :key="label"
            class="ml-lg-3 ml-0 my-2 my-lg-0 d-flex flex-lg-row flex-column w-100 justify-content-center"
            :class="colClass"
          >
            <div
              :class="[name]"
              class="formContainer__inputsContainer__inputs__container"
            >
              <!-- <label class="form__label d-block" :class="name" v-if="component === 'TextInput'" >{{ label }}</label > -->
              <component
                v-on="$listeners"
                :is="component"
                :name="name"
                :multiple="multiple"
                v-bind="attrs"
                :label="label"
                v-model="formData[name]"
                :options="options && options.length ? options : null"
              >
                <template v-slot:label>
                  <label class="form__label d-block" :class="name">{{ label }}</label>
                </template>
                <template v-if="options.length">
                  <option
                    v-for="({ value, text, ...rest }, i) in options"
                    v-bind="rest"
                    :key="i"
                    :value="value"
                  >
                    {{ text }}
                  </option>
                </template>
              </component>
            </div>
            <div class="formContainer__verticalLine" :class="name"></div>
            <hr class="d-md-none d-block w-100" />
          </div>
        </div>
        <div>
          <button
            class="btn btn-outline-primary align-self-center mr-2"
            @click="$router.push('/listings')"
            v-if="$route.name != 'Listings'"
          >
            {{ $t("listview") }}
          </button>
          <button class="btn btn-primary align-self-center" type="submit">
            {{ $t("filter") }}
          </button>
        </div>
      </b-form>
    </div>
  </Card>
</template>

<script>
import Card from "@/components/general/Card.vue";
import CardHeading from "../CardHeading.vue";
// import Form from "../../ui/form/Form.vue";
import MultiselectInput from "@/components/ui/form/controls/MultiSelectInput.vue";
import TextInput from "@/components/ui/form/controls/TextInput.vue";
import { ValidationObserver } from "vee-validate";
import municipality from "@/constants/municipality";

export default {
  components: {
    Card,
    CardHeading,
    MultiselectInput,
    ValidationObserver,
    // Form,
    TextInput,
  },
  data() {
    return {
      resetForm: false,
      formData: {},
    };
  },
  methods: {
    onSubmit() {
      const formattedFormData = JSON.parse(JSON.stringify(this.formData));
      if (formattedFormData.numOfRooms) {
        formattedFormData.numOfRooms = formattedFormData.numOfRooms.value;
      }
      if (formattedFormData.incomerequirements != null) {
        formattedFormData.incomerequirements = formattedFormData.incomerequirements.value;
      }
      this.$emit("on-filter", formattedFormData);
    },
  },
  computed: {
    nsFiltersLocale() {
      return this.$t("listingsKey.filters.nonSuitableFilters");
    },
    formFields() {
      return [
        {
          name: "municipalities",
          placeholder: this.nsFiltersLocale.municpality.placeholder,
          component: "MultiselectInput",
          openDirection: "bottom",
          // validate: false,
          value: [],
          defaultValue: [],
          // colClass: "mb-2",
          multiple: true,
          searchable: true,
          options: municipality,
          // externalLabel: true,
          label: this.nsFiltersLocale.municpality.label,
        },
        {
          validate: false,
          mode: "passive",
          name: "maxRent", //houseprice
          component: "TextInput",
          value: "",
          placeholder: "€ 1.000",
          colClass: "align-self-baseline ",
          masked: true,
          mask: [
            {
              mask: "€ num",
              blocks: {
                num: {
                  mask: Number, // enable number mask
                  scale: 2,
                  signed: false,
                  thousandsSeparator: ".",
                  padFractionalZeros: false,
                  normalizeZeros: true,
                  radix: ",",
                  // additional number interval options (e.g.)
                  min: 0,
                  max: 99999999,
                },
              },
            },
          ],
          defaultValue: "",
          externalLabel: true,
          label: this.nsFiltersLocale.rentalPrice.label,
        },
        {
          // mode: "passive",
          name: "numOfRooms",
          placeholder: this.nsFiltersLocale.totalRooms.label,
          component: "MultiselectInput",
          openDirection: "bottom",
          // validate: false,
          multiple: false,
          emitValueFromObject: false,
          searchable: false,
          value: "",
          defaultValue: "",
          colClass: "ml-auto",
          // stacked: true,
          options: [
            { text: 1, value: 1 },
            { text: 2, value: 2 },
            { text: 3, value: 3 },
            { text: 4, value: 4 },
            {
              text: 5 + " " + this.nsFiltersLocale.totalRooms.orMore,
              value: 5,
            },
          ],
          // externalLabel: true,
          label: this.nsFiltersLocale.totalRooms.label,
        },
        // {
        //   // mode: "passive",
        //   openDirection: "bottom",
        //   name: "incomerequirements",
        //   placeholder: this.nsFiltersLocale.incomeRequirements.label,
        //   component: "MultiselectInput",
        //   // validate: false,
        //   multiple: false,
        //   emitValueFromObject: false,
        //   searchable: false,
        //   value: "",
        //   defaultValue: "",
        //   colClass: "ml-auto",
        //   // stacked: true,
        //   options: [
        //     {
        //       text: this.nsFiltersLocale.incomeRequirements.options[0],
        //       value: true, //sufficient
        //     },
        //     {
        //       text: this.nsFiltersLocale.incomeRequirements.options[1],
        //       value: false, //insufficient
        //     },
        //   ],
        //   // externalLabel: true,
        //   label: this.nsFiltersLocale.incomeRequirements.label,
        // },
      ];
    },
  },
  created() {
    this.formFields.forEach((field) => this.$set(this.formData, field.name, field.value));
  },
};
</script>

<style lang="scss">
.formContainer .form {
  &__label {
    @include font(1rem, #aeaeae, 500);
  }
}

.formContainer__verticalLine {
  background: #e8e8e8;
  height: 65px;
  width: 1px;
  margin: 0 12px;
  align-self: baseline;
  // @include lg {
  //   align-self: flex-start;

  //   margin: 0 10px;
  //   margin-left: 30px;
  //   height: 55px;
  // }
  @include lg {
    display: none;
  }
}

// .formContainer__verticalLine.numOfRooms {
//   @include md {
//     display: none;
//   }
// }
.formContainer {
  &__inputsContainer {
    @include lg {
      flex-basis: 100%;
      max-width: 100%;
      flex-direction: column;
      padding-bottom: 20px;
    }

    &__inputs {
      @include lg {
        width: 100%;
      }
    }
  }

  &__btn {
    margin-left: auto;
    transform: translateY(-10px);

    @include lg {
      width: 200px;
      margin: auto;
      margin-top: 20px;
      transform: translateY(0px);
    }

    @include md {
      width: 330px;
    }

    @include xs {
      width: 90%;
    }
  }
}

.formContainer .multiselect__select {
  z-index: 100 !important;
  background: #f3f3f3;
  border-radius: 50%;
  top: 6px;
  width: 25px;
  height: 25px;
}

.formContainer .multiselect__select::before {
  z-index: 100 !important;
  border-color: $primary !important;
  border-width: 2px 2px 0 0 !important;
  height: 8px !important;
  width: 8px !important;
  top: -0.2px;
  left: 0.3px;
}

.formContainer .multiselect {
  @include lg {
    transform: translateY(-8px);
  }

  @include sm {
    transform: translateY(0);
  }

  &__tags {
    width: 210px;
    border: none;
    padding-left: 0;

    @include lg {
      width: 210px;
    }

    @include md {
      width: 100%;
    }
  }
}

.formContainer.numOfRooms .multiselect {
  width: 180px;

  @include md {
    width: 100%;
  }
}

.formContainer .form__label.houseprice {
  @include lg {
    text-align: center;
  }

  @include sm {
    text-align: left;
  }
}

.formContainer .multiselect__placeholder {
  font-weight: bold;
  color: #2c2c2c;
  font-size: 1rem;
}

.formContainer .multiselect__content-wrapper {
  z-index: 1000;
}

.formContainer .form-group {
  margin-bottom: 0 !important;
}

// input styling
.formContainer input.form-control {
  font-weight: bold;
  color: #2c2c2c;
  border: none;
  padding: 0;

  @include lg {
    text-align: center;
  }

  @include sm {
    padding: 1rem;
    text-align: left !important;
    padding-left: 3px;
  }
}

.formContainer .form-control:focus {
  box-shadow: none;
  border-color: transparent;
}

.formContainer .multiselect__content-wrapper {
  top: 50px;
  background: #fbfbfb;
  max-height: 216.625px !important;
}

.formContainer .multiselect__tags-wrap {
  display: flex;
  flex-wrap: wrap;
}

.formContainer .multiselect__tags-wrap .badge-pill {
  margin-top: 3px;
}

.formContainer .numOfRooms,
.formContainer .municipalities {
  @include lg {
    width: 100%;
  }

  // @include sm {
  //   width: 80%;
  //   margin: auto;
  // }
}

.formContainer .multiselect__single {
  color: #2c2c2c;
  font-weight: bold;
}

.formContainer__inputsContainer__inputs__container.maxRent {
  @include md {
    width: 100%;
  }
}

.formContainer__inputsContainer__inputs__container.incomerequirements {
  @include md {
    width: 100%;
  }
}

.listingFilter {
  input.form-control {
    background: transparent !important;
    border: none !important;
  }

  .multiselect__tags,
  .custom-select {
    border: none !important;
    background: transparent !important;
  }
}
</style>
