<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.58148 15.5436L4.20631 10.1685C3.5979 9.56005 3.5979 8.43983 4.20631 7.83142L9.58148 2.45624C10.1899 1.84784 11.3101 1.84784 11.9185 2.45624L17.2937 7.83142C17.9021 8.43983 17.9021 9.56005 17.2937 10.1685L11.9185 15.5436C11.3101 16.152 10.1899 16.152 9.58148 15.5436V15.5436Z"
      :stroke="stroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.75 13.1716L7.11371 17.5353"
      :stroke="stroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3862 17.5353L18.7499 13.1716"
       :stroke="stroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props:{
    stroke:{
      type:String,
      default:'#0A9250'
    }
  }
};
</script>

<style lang="scss" scoped></style>
