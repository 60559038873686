<template>
  <Card :cardClass="['d-flex flex-column mb-3 listing ', externalClass]">
    <RouterLink
      :to="listingLink"
      class="d-flex flex-column"
      :style="{ cursor: listingLink ? 'pointer' : 'default' }"
    >
      <div class="v-application--is-ltr w-100" v-if="listing.media">
        <v-carousel hide-delimiters height="200" :show-arrows="listing.media.length > 1">
          <v-carousel-item
            v-for="(item, i) in listing.media"
            :key="i"
            :src="item.name"
          ></v-carousel-item>
        </v-carousel>
      </div>
      <div
        v-if="!listing.media || !listing.media.length"
        class="broker__listing__draft__imgBox"
      ></div>
      <div v-else class="w-100 position-relative">
        <!-- <img class="img-fluid listing__thumbnail w-100" :class="`${externalClass[0]}__img`"
          v-lazy="listing.media[0].name" /> -->
        <div v-if="listing.reacted" class="listing__thumbnail__caption">
          <span class="pl-3 listing__thumbnail__caption__text">
            {{ $t("Requested") }}
          </span>
        </div>

        <div
          class="bg-danger w-100 text-white p-2 text-center position-absolute bottom-0"
          v-if="listing.assigned"
        >
          <span class="pl-2 listing__caption__text">
            {{ $t("Rented, property unavailable") }}
          </span>
        </div>
        <slot name="status"></slot>
      </div>
      <div
        class="listing__textContent d-flex flex-column"
        :class="[
          listingtextcontentExternalClass,
          { 'pb-0': $route.name === 'ListingsSaved' },
        ]"
      >
        <p
          v-if="listing.price"
          class="fw-bold listing__rent mb-2"
          :class="{ draft: listing.currentStatus === 'draft' }"
        >
          <span class="listing__rent__price">{{ listing.price | formatCurrency }}</span>
          <span class="listing__rent__perMonthText"> / {{ $t("perMonth") }}</span>
        </p>

        <CardHeading
          v-if="listing.propertyType && listing.streetName"
          :heading="listing.title"
          :externalClass="{ draft: listing.currentStatus === 'draft' }"
        />

        <p class="listing__address text-gray fw-medium mb-2" v-if="listing.address">
          {{ listing.address }}
        </p>

        <!-- <div class="listing__line"></div> -->
        <slot name="info">
          <div
            v-if="
              listing.currentStatus !== 'draft' &&
              $route.name !== 'ListingsSaved' &&
              $route.name !== 'BrokerListings'
            "
            class="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center"
          >
            <div class="d-flex my-2" v-if="listing.interior">
              <Sofa fill="#A9A9A9" />
              <span class="fw-medium listing__bottom__text ml-2">{{
                listingInterior
              }}</span>
            </div>
            <div class="d-flex my-2">
              <Door stroke="#A9A9A9" />
              <span class="fw-medium listing__bottom__text ml-2"
                >{{ listing.totalRooms }} {{ $t("rooms") }}</span
              >
            </div>
            <div class="d-flex my-2">
              <Dimension stroke="#A9A9A9" />
              <span class="fw-medium listing__bottom__text ml-2">{{
                listing.livingArea
              }}</span>
            </div>
          </div>
          <!-- <div class="mt-3">
            <b-badge class="p-2" variant="primary" v-if="listing.reacted">
              {{ $t("requested") }}
            </b-badge>
          </div> -->
        </slot>
      </div>
    </RouterLink>
    <div class="px-4 py-3">
      <slot name="controls"></slot>
    </div>
  </Card>
</template>

<script>
import Card from "../Card.vue";
import CardHeading from "../CardHeading.vue";
import Dimension from "@/components/svg/Dimension";
import Door from "@/components/svg/Door";
import Sofa from "@/components/svg/Sofa";
export default {
  props: {
    listing: {
      type: Object,
      required: true,
    },
    externalClass: {
      type: Array,
      required: false,
      default: () => [],
    },
    listingtextcontentExternalClass: {
      type: String,
      required: false,
      default: "",
    },
    listingLink: {
      type: String,
      default: "/",
    },
  },
  components: { Card, CardHeading, Dimension, Door, Sofa },
  computed: {
    listingInterior() {
      const interior = this.$t("singleListing.interior");
      return interior[this.listing.interior.toLowerCase()];
    },
    propertyTypeLocale() {
      return this.$t("singleListing.propertyType");
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.broker {
  &__listing {
    &__draft {
      &__imgBox {
        padding-right: 250px;
        height: 250px;

        @include sm {
          padding-right: 190px;
        }
      }
    }

    &__card {
      &__img {
        @include sm {
          max-width: 200px;
          height: 200px;
          padding-left: 10px;
          padding-top: 10px;
          align-self: center;
        }

        @include xs {
          max-width: 100%;
          height: auto;
          padding-left: 0px;
          padding-top: 0px;
          align-self: initial;
        }
      }
    }
  }
}

.recent-listing {
  background: $white;
  border: 1.5px solid $gray2;
  border-radius: 10px;
  overflow: hidden;
  
  @include xs {
    // max-width: 400px;
    margin: auto;
  }

  &__img {
    @include sm {
      max-width: 150px;
    }

    @include xs {
      max-width: 100%;
    }
  }
}

.listing {
  &__line {
    background: $gray2;
    height: 2px;
    margin-top: 10px;
  }

  &__bottom {
    &__text {
      font-size: 0.875rem;
      color: #6c727f;
    }
  }

  &__rent.draft {
    color: $gray;
  }

  &__rent {
    &__price {
      color: $primary !important;
      font-size: 1.5rem;
      font-weight: 800;
    }

    &__perMonthText {
      color: rgba(#000929, 0.5) !important;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  &__address {
    $textcolor: rgba(#000929, 0.5) !important;
    @include font(1rem, $textcolor, 500);

    @include sm {
      font-size: 0.9375rem;
    }
  }

  &__rent {
    @include sm {
      font-size: 0.9375rem;
    }
  }

  &__thumbnail {
    height: 200px;
    max-width: 100%;
    object-fit: cover;
    object-position: top;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    @include xs {
      width: auto;
      height: auto;
    }

    &__caption {
      background: #54b16b;
      padding: 10px 0;
      position: absolute;
      bottom: 0;
      width: 100%;

      &__text {
        @include font(0.75rem, $white, 700);
      }
    }
  }

  &__textContent {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
    @include lg {
      padding-top: 30px;
    }
  }
}

.saved-listings {
  &__listing {
    &__img {
      max-width: 100%;
      // @include sm {
      //   max-width: 150px;
      // }
      // @include xs {
      //   max-width: 100%;
      // }
    }
  }
}
</style>
