var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"cardClass":['d-flex flex-column mb-3 listing ', _vm.externalClass]}},[_c('RouterLink',{staticClass:"d-flex flex-column",style:({ cursor: _vm.listingLink ? 'pointer' : 'default' }),attrs:{"to":_vm.listingLink}},[(_vm.listing.media)?_c('div',{staticClass:"v-application--is-ltr w-100"},[_c('v-carousel',{attrs:{"hide-delimiters":"","height":"200","show-arrows":_vm.listing.media.length > 1}},_vm._l((_vm.listing.media),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item.name}})}),1)],1):_vm._e(),(!_vm.listing.media || !_vm.listing.media.length)?_c('div',{staticClass:"broker__listing__draft__imgBox"}):_c('div',{staticClass:"w-100 position-relative"},[(_vm.listing.reacted)?_c('div',{staticClass:"listing__thumbnail__caption"},[_c('span',{staticClass:"pl-3 listing__thumbnail__caption__text"},[_vm._v(" "+_vm._s(_vm.$t("Requested"))+" ")])]):_vm._e(),(_vm.listing.assigned)?_c('div',{staticClass:"bg-danger w-100 text-white p-2 text-center position-absolute bottom-0"},[_c('span',{staticClass:"pl-2 listing__caption__text"},[_vm._v(" "+_vm._s(_vm.$t("Rented, property unavailable"))+" ")])]):_vm._e(),_vm._t("status")],2),_c('div',{staticClass:"listing__textContent d-flex flex-column",class:[
        _vm.listingtextcontentExternalClass,
        { 'pb-0': _vm.$route.name === 'ListingsSaved' },
      ]},[(_vm.listing.price)?_c('p',{staticClass:"fw-bold listing__rent mb-2",class:{ draft: _vm.listing.currentStatus === 'draft' }},[_c('span',{staticClass:"listing__rent__price"},[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.listing.price)))]),_c('span',{staticClass:"listing__rent__perMonthText"},[_vm._v(" / "+_vm._s(_vm.$t("perMonth")))])]):_vm._e(),(_vm.listing.propertyType && _vm.listing.streetName)?_c('CardHeading',{attrs:{"heading":_vm.listing.title,"externalClass":{ draft: _vm.listing.currentStatus === 'draft' }}}):_vm._e(),(_vm.listing.address)?_c('p',{staticClass:"listing__address text-gray fw-medium mb-2"},[_vm._v(" "+_vm._s(_vm.listing.address)+" ")]):_vm._e(),_vm._t("info",function(){return [(
            _vm.listing.currentStatus !== 'draft' &&
            _vm.$route.name !== 'ListingsSaved' &&
            _vm.$route.name !== 'BrokerListings'
          )?_c('div',{staticClass:"d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center"},[(_vm.listing.interior)?_c('div',{staticClass:"d-flex my-2"},[_c('Sofa',{attrs:{"fill":"#A9A9A9"}}),_c('span',{staticClass:"fw-medium listing__bottom__text ml-2"},[_vm._v(_vm._s(_vm.listingInterior))])],1):_vm._e(),_c('div',{staticClass:"d-flex my-2"},[_c('Door',{attrs:{"stroke":"#A9A9A9"}}),_c('span',{staticClass:"fw-medium listing__bottom__text ml-2"},[_vm._v(_vm._s(_vm.listing.totalRooms)+" "+_vm._s(_vm.$t("rooms")))])],1),_c('div',{staticClass:"d-flex my-2"},[_c('Dimension',{attrs:{"stroke":"#A9A9A9"}}),_c('span',{staticClass:"fw-medium listing__bottom__text ml-2"},[_vm._v(_vm._s(_vm.listing.livingArea))])],1)]):_vm._e()]})],2)]),_c('div',{staticClass:"px-4 py-3"},[_vm._t("controls")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }