<template>
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_513_548)">
      <path
        d="M13.0624 2.75H17.1874C17.9468 2.75 18.5624 3.36561 18.5624 4.125V17.875C18.5624 18.6344 17.9468 19.25 17.1874 19.25H13.0624M6.96662 10.6599C6.77678 10.6614 6.62412 10.8166 6.62565 11.0064C6.62718 11.1962 6.78231 11.3489 6.97216 11.3474C7.162 11.3458 7.31465 11.1907 7.31312 11.0009C7.31312 11.0006 7.31312 11.0003 7.31312 11V11C7.31362 10.8106 7.16054 10.6567 6.9712 10.6562C6.97059 10.6562 6.96998 10.6562 6.96937 10.6562L6.96662 10.6599Z"
        :stroke="stroke"
        stroke-width="1.99999"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5278 21.3088L1.27782 20.1299C0.939062 20.082 0.687312 19.7919 0.687493 19.4498V3.41455L0.687493 3.41452C0.687242 3.09007 0.913848 2.80962 1.23111 2.74172L9.48109 0.705804C9.85237 0.626321 10.2178 0.862872 10.2973 1.23415C10.3074 1.28164 10.3125 1.33007 10.3125 1.37864V20.6286V20.6286C10.3123 21.0083 10.0043 21.3159 9.62459 21.3157C9.59221 21.3156 9.55987 21.3133 9.52781 21.3088L9.5278 21.3088V21.3088Z"
        :stroke="stroke"
        stroke-width="1.99999"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_513_548">
        <rect width="19.25" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>


<script>
export default {
  props: {
    stroke: {
      type: String,
      default: "#0A9250",
    },
  },
};
</script>
