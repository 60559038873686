<template>
  <h5 class="card__heading fw-bold text-black" :class="externalClass">
    {{ heading }}
  </h5>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true,
    },
    externalClass: {
      type: [String, Object],
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  &__heading {
    font-size: 22px;
    font-weight: 700;
  }
  &__heading.draft {
    color: $gray !important;
  }
  
}

.about-qii{
  &__heading{
    font-size:2rem;
  }
}
</style>
